import * as React from 'react';
import { graphql } from "gatsby"
import { GatsbyImage } from 'gatsby-plugin-image'
import { Layout } from '../components/common/layout'
import { Box, makeStyles, Theme, createStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    maxWidth: 1500,
    margin: '40px auto',
    padding: '20px',
  },
  media: {
    width: '100%',
    margin: '20px 0',
  },
  title: {
    marginTop: 20,
    marginBottom: 20,
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(30),
  },
  description: {
    fontSize: theme.typography.pxToRem(18),
  }
}));

export const productQuery = graphql`
  query($id: String!) {
    markdownRemark(id: {eq: $id}) {
        id
        frontmatter {
          title
          slug
          thumbnail {
            childImageSharp {
                gatsbyImageData
              }
        
          }
          date(formatString: "DD MMM YYYY")
        }
        html
    }
  }
`;

const CompanyDetails = ({ data }) => {
  const classes = useStyles();
  return <Layout>
    <Box component="div" className={classes.root}>
      <GatsbyImage
        className={classes.media}
        image={data.markdownRemark.frontmatter.thumbnail.childImageSharp.gatsbyImageData}
        alt="title" />
        <Typography className={classes.title} variant='h1'>{data.markdownRemark.frontmatter.title}</Typography>
        <Box component="p" className={classes.description} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Box>
  </Layout >
};

// must export as default
export default CompanyDetails;
